import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router} from 'react-router-dom';

import Main from './components/Main'
import Survey from './components/Survey'

require('./App.scss');

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path={"/"} component={Main} />
        <Route exact path={"/survey"} component={Survey} />
      </Router>
    );
  }
}

export default connect()(App);
