import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateSize} from '../actions/size-actions';

class Size extends Component {
  createSizeList() {
    return this.props.sizes.map((size) => {
      if (this.props.product.sizeLimit >= size.id && this.props.character.activeHoodieSizes.includes(size.size)) {
        return (
          <div key={size.id} onClick={() => this.props.updateSize(size)} >
            {this.props.size.id === size.id ?
              <div className='sizeswatch sizeswatch-active'>{size.size}</div> :
              <div className='sizeswatch'>{size.size}</div>
            }
          </div>
        );
      }
      return ('');
    });
  }

  render() {
    return (
      <section>
          {this.createSizeList()}
      </section>
    )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    character: state.character,
    product: state.product,
    sizes: state.sizes,
    size: state.size,
  }
};

const mapActionToProps = {
  updateSize: updateSize,
};

export default connect(mapStateToProps, mapActionToProps)(Size);
