export default function () {
  return [
    {
      id: 0,
      size: 'S',
    },
    {
      id: 1,
      size: 'M',
    },
    {
      id: 2,
      size: 'L',
    },
    {
      id: 3,
      size: 'XL',
    },
    {
      id: 4,
      size: '2XL',
    },
    {
      id: 5,
      size: '3XL',
    },
    {
      id: 6,
      size: '4XL',
    }
  ]
}
