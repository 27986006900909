import React, { Component } from 'react';
import { connect } from 'react-redux';

import {updateCharacter} from '../actions/character-actions';
import {updateProduct} from '../actions/product-actions';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';

class CharacterList extends Component {
  selectCharacter(character) {
      this.props.updateCharacter(character);
      let colorIdx, productIdx, sizeIdx;

      this.props.colors.forEach(function (color) {
        if (color.color === character.welcomeColor) colorIdx = color.id;
      });

      this.props.sizes.forEach(function (size) {
        if (size.size === character.welcomeSize) sizeIdx = size.id;

      });

      this.props.products.forEach(function (product) {
        if (product.folder === character.welcomeProduct) productIdx = product.id;
      });

      this.props.updateColor(this.props.colors[colorIdx])
      this.props.updateSize(this.props.sizes[sizeIdx])
      this.props.updateProduct(this.props.products[productIdx])
  }

  createCharactersList() {
    return this.props.characters.map((character) =>{
      return (
        <div key={character.id} onClick={() => this.selectCharacter(character)} >
          {this.props.character.id === character.id ?
            <div className='characterswatch characterswatch-active'>{character.nickname}</div> :
            <div className='characterswatch'>{character.nickname}</div>
          }
        </div>
      );
    });
  }

  render() {
    return (
      <section>
          {this.createCharactersList()}
      </section>
    )
  }
}

const mapStateToProps = (state, props)=>{
  return{
    characters: state.characters,
    character: state.character,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,
  }
};

const mapActionToProps = {
  updateCharacter: updateCharacter,
  updateProduct: updateProduct,
  updateColor: updateColor,
  updateSize: updateSize,
};
export default connect(mapStateToProps, mapActionToProps)(CharacterList);
