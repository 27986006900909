export default function () {
  return [
    {
      id: 0,
      nickname: '195cm / 6.4ft',
      full: '@loloveskiing',
      age: 22,
      height: 194,
      weight: 86,
      preferredSize: "2XL",
      hobby: 'Skiing, Skateborading, Piano, Video Games',
      folder: 'lolo',
      welcomeProduct: 'hoodie',
      welcomeSize: 'L',
      welcomeColor: 'olive',
      activeProducts: ['hoodie'],
      activeHoodieSizes: ['L', 'XL', '2XL', '3XL', '4XL'],
      //activeHoodieColors: ['skyblue', 'black', 'grey', 'plum', 'beige', 'olive'],
      activeHoodieColors: ['olive'],
    },
    {
      id: 1,
      nickname: '186cm / 6.1ft',
      full: '@zarrpatrick',
      age: 25,
      height: 187,
      weight: 76,
      preferredSize: "L",
      hobby: 'Photography, Running',
      folder: 'pat',
      welcomeProduct: 'hoodie',
      welcomeSize: 'M',
      welcomeColor: 'skyblue',
      activeProducts: ['hoodie'],
      activeHoodieSizes: ['M', 'L', 'XL', '2XL'],
      activeHoodieColors: ['skyblue'],
      //activeHoodieColors: ['darkgrey', 'skyblue', 'yellow'],
    },
    {
      id: 2,
      nickname: '165cm / 5.4ft',
      full: '@tobi_hoehenwarter',
      age: 16,
      height: 165,
      weight: 60,
      preferredSize: "S",
      hobby: 'Freestyle Skiing, Trampoline',
      folder: 'tobi',
      welcomeProduct: 'hoodie',
      welcomeSize: 'S',
      welcomeColor: 'plum',
      activeProducts: ['hoodie'],
      activeHoodieSizes: ['S', 'M', 'L', 'XL'],
      activeHoodieColors: ['plum'],
      //activeHoodieColors: ['black', 'plum', 'red', 'blue'],
    },
    {
      id: 3,
      nickname: '170cm / 5.5ft',
      full: '@lauraathina',
      age: 22,
      height: 168,
      weight: 57,
      preferredSize: "S",
      hobby: 'Surfing, Snowboarding, Concrete Surfskating, Running, Yoga',
      folder: 'laura',
      welcomeProduct: 'hoodie',
      welcomeSize: 'S',
      welcomeColor: 'skyblue',
      activeProducts: ['hoodie'],
      activeHoodieSizes: ['S', 'M', 'L'],
      activeHoodieColors: ['skyblue'],
      //activeHoodieColors: ['skyblue', 'black', 'grey', 'plum', 'beige', 'olive'],
    },
  ]
}
