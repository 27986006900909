import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import {updateCharacter} from '../actions/character-actions';
import {updateProduct} from '../actions/product-actions';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';

class Character extends Component {
  render() {
    if (!this.props.character.nickname){
      return (<h2>Sorry, something went terribly wrong.</h2>);
    }

    if (!!this.props.character.nickname) {
      return (
        <div className={'option-window float-right right-dashboard'}>
          <div className='detail-block'>
            <h1 className='nickname'>{this.props.character.nickname}</h1>
            <h2 className='fullname'>{this.props.character.full}</h2>
            <div className='detail-block'>
              <div className='title align-right'>AGE:</div>
              <h3 className='detail'>{this.props.character.age} y.o.</h3>
              <div className='title align-right'>HEIGHT:</div>
              <h3 className='detail'>{this.props.character.height} cm</h3>
              <div className='title align-right'>WEIGHT:</div>
              <h3 className='detail'>{this.props.character.weight} kg</h3>
              <div className='title align-right'>PREFERRED SIZE:</div>
              <h3 className='detail'>{this.props.character.preferredSize}</h3>
              <div className='title align-right'>HOBBIES:</div>
              <h3 className='fullname'>{this.props.character.hobby}</h3>
              <Link to="/survey" className="survey-link-text">
              <div className='survey-link'>CLAIM YOUR -15% OFF!</div>
              </Link>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, props)=>{
  return{
    characters: state.characters,
    character: state.character,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,
  }
};

const mapActionToProps = {
  updateCharacter: updateCharacter,
  updateProduct: updateProduct,
  updateColor: updateColor,
  updateSize: updateSize,
};

export default connect(mapStateToProps, mapActionToProps)(Character);
