export default function () {
  return [
    {
      id: 0,
      category: 'T-Shirt',
      sizeLimit: '4',
      availableColors: ['black', 'darkgrey', 'grey', 'white'],
      folder: 'tshirt'
    },
    {
      id: 1,
      category: 'Hoodie',
      sizeLimit: '6',
      availableColors: ['black', 'darkgrey', 'grey', 'blue', 'plum', 'red', 'beige', 'red', 'olive', 'skyblue', 'yellow'],
      folder: 'hoodie'
    },
    {
      id: 2,
      category: 'Vendimia Jacket',
      sizeLimit: '5',
      availableColors: ['darkgrey', 'black', 'olive', 'blue'],
      folder: 'vendimia'
    }
  ]
}
