import React, { Component } from 'react';
import { connect } from 'react-redux';

class ImagePreloader extends Component {
  render() {
    const images = require.context('../../public/images', true);

    return (
      <div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/skyblue/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/skyblue/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/skyblue/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/skyblue/3XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/skyblue/4XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/plum/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/plum/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/plum/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/plum/3XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/plum/4XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/olive/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/olive/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/olive/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/olive/3XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/olive/4XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/grey/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/grey/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/grey/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/grey/3XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/grey/4XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/black/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/black/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/black/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/black/3XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/black/4XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/beige/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/beige/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/beige/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/beige/3XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./lolo/hoodie/beige/4XL.jpg`) + ')'}}></div>

        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/skyblue/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/skyblue/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/skyblue/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/plum/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/plum/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/plum/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/olive/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/olive/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/olive/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/grey/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/grey/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/grey/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/black/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/black/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/black/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/beige/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/beige/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./laura/hoodie/beige/L.jpg`) + ')'}}></div>

        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/black/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/black/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/black/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/black/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/blue/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/blue/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/blue/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/blue/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/plum/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/plum/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/plum/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/plum/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/red/S.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/red/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/red/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./tobi/hoodie/red/XL.jpg`) + ')'}}></div>

        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/darkgrey/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/darkgrey/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/darkgrey/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/darkgrey/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/skyblue/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/skyblue/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/skyblue/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/skyblue/2XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/yellow/M.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/yellow/L.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/yellow/XL.jpg`) + ')'}}></div>
        <div style={{backgroundImage: 'url(' + images(`./pat/hoodie/yellow/2XL.jpg`) + ')'}}></div>
      </div>
    );
  }
}

export default connect()(ImagePreloader);
