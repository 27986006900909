import React, { Component } from 'react';
import { connect } from 'react-redux';

import {updateCharacter} from '../actions/character-actions';
import {updateProduct} from '../actions/product-actions';
import {updateColor} from '../actions/color-actions';
import {updateSize} from '../actions/size-actions';

import Dashboard from './Dashboard';
import Character from './Character';
import ImagePreloader from './ImagePreloader';

class Main extends Component {
  constructor (props) {
    super(props);

    this.state = {
        imgurl: '',
    }

    //Needed for the initial load: take first characters "welcome" parameters
    this.props.updateCharacter(this.props.characters[0]);
    const welcomeSizeIdx = this.props.sizes.findIndex(size =>
      size.size === this.props.characters[0].welcomeSize);
    const welcomeColorIdx = this.props.colors.findIndex(color =>
      color.color === this.props.characters[0].welcomeColor);
    const welcomeProductIdx = this.props.products.findIndex(product =>
      product.folder === this.props.characters[0].welcomeProduct);

    this.props.updateSize(this.props.sizes[welcomeSizeIdx]);
    this.props.updateColor(this.props.colors[welcomeColorIdx]);
    this.props.updateProduct(this.props.products[welcomeProductIdx]);
  }

  componentDidUpdate() {
    this.updateImgUrl()
  }

  updateImgUrl(){
    const images = require.context('../../public/images', true);
    const imgsrc = images(`./${this.props.character.folder}/${this.props.product.folder}/${this.props.color.color}/${this.props.size.size}.jpg`);

    if (this.state.imgurl !== imgsrc) {
      this.setState({imgurl: imgsrc})
    }
  }

  render() {
    if (!this.props.character.nickname){
      return (<h2>Sorry, something went terribly wrong.</h2>);
    }

    else {
      return (
        <div
          className='bg-image'
          style={{backgroundImage: 'url(' + this.state.imgurl + ')'}}
        >
          <Dashboard />
          {/*//<Character />*/}
          <ImagePreloader/>
        </div>
      )
    }
  }
}

const mapStateToProps = (state, props)=>{
  return{
    characters: state.characters,
    character: state.character,

    products: state.products,
    product: state.product,

    colors: state.colors,
    color: state.color,

    sizes: state.sizes,
    size: state.size,
  }
};

const mapActionToProps = {
  updateCharacter: updateCharacter,
  updateProduct: updateProduct,
  updateColor: updateColor,
  updateSize: updateSize,
};

export default connect(mapStateToProps, mapActionToProps)(Main);
