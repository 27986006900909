import { UPDATE_CHARACTER } from '../actions/character-actions';

export default function characterReducer(state = '', { type, payload
  }) {
  switch (type) {
    case UPDATE_CHARACTER :
      return payload;
    default:
      return state;
  }
}
