import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Color from './Color';
import Size from './Size';
import CharacterList from './CharacterList';

class Dashboard extends Component {
  render() {
    return (
      <div className={'option-window float-left left-dashboard'}>
        <div className='detail-block'>
          <div className='title'>
          </div>
          <CharacterList />
        </div>
        {/*<div className='detail-block'>
          <div className='title'>
          </div>
          <Color />
        </div>*/}
        <div className='detail-block'>
          <div className='title'>
          </div>
          <Size />
        </div>
        <div className='clear'></div>
        {/*
        <Link to="/survey" className="survey-link-text">
        <div className='survey-link'>CLAIM YOUR -15% OFF!</div>
        </Link>
        */}
      </div>
    )
  }
}

export default connect()(Dashboard);
